<template>
    <section class="white-container stream">
        <!-- время трансляции -->
        <span class="stream-time">
            {{ getLiveStreamTimeLabel}} {{ getFormattedDate(getStream.start_date_time)  }}
        </span>

        <!-- заголовок -->
        <h5>
            {{ getStream[getLang ? 'title' : 'title_eng'] }}
        </h5>

        <!-- просмотры -->
        <p class="stream-views">
            {{ getStream.view_count }}
        </p>

        <!--  стрим видео -->
        <div  class="stream-video">
            <iframe width="100%" :src="getStream.live_stream" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
            </iframe>
           <!--<p class="stream-video__iframe" v-html='getStream.live_stream'></p>-->
        </div>

        <div class="stream-text" v-html="getStream[getLang ? 'text' : 'text_eng']"></div>

        <!-- новостные теги -->
        <tags-list :tags="getTagList"/>

        <!-- соц. иконки -->
        <share-social :content="getStream"/>
    </section>
</template>

<script>
import formatDate from '@/mixins/formatDate'
import TagsList from './TagsList'
import ShareSocial from './ShareSocial'

export default {
  name: 'StreamContent',

  data () {
    return {
      views: 21335,
      getTempIdNews: 0
    }
  },

  mounted () {
    this.$store.dispatch('getPublicLiveStreamById', this.$route.params.id)
  },

  watch: {
    '$route' () {
      this.$store.dispatch('getPublicLiveStreamById', this.$route.params.id)
      this.$store.commit('setCurrentLiveStream', null)
    },

    getLang () {
      this.$store.dispatch('getPublicLiveStreamById', this.$route.params.id)
    }
  },

  computed: {
    getLang () {
      return this.$store.getters.GET_LANG
    },

    getStream () {
      const content = this.$store.getters.getCurrentLiveStream
      return content
    },

    getTagList () {
      const news = this.getStream
      return news.tags_list ? news.tags_list : []
    },

    getImages () {
      const news = this.getStream
      return news.mediaList ? news.mediaList : []
    },

    getLiveStreamTimeLabel () {
      let dateStart = this.getStream && 'start_date_time' in this.getStream ? this.getStream.start_date_time : null
      let dateEnd = this.getStream && 'end_date_time' in this.getStream ? this.getStream.end_date_time : null
      let label = ''

      if (dateStart && dateEnd) {
        const now = new Date().getTime()
        dateStart = new Date(dateStart).getTime()
        dateEnd = new Date(dateEnd).getTime()

        if (dateStart < now && dateEnd < now) {
          label = ''
        } else if (dateStart < now && dateEnd > now) {
          label = this.getLang ? 'идет эфир' : 'online'
        } else if (dateStart > now && dateEnd > now) {
          label = this.getLang ? 'скоро начнется' : 'starting soon'
        }
      }

      return label
    }
  },

  methods: {
    playVideo () {
      this.isPlay = true
      this.player.playVideo()
    },

    playing (val) {
      console.log(val)
    },

    paused () {
      this.isPlay = false
    }
  },

  components: {
    ShareSocial,
    TagsList
  },

  mixins: [
    formatDate
  ]
}
</script>

<style lang="scss" scoped>
.stream {

  h5 {
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;

    margin-bottom: 1.18rem;
  }

  &-time {
    display: inline-flex;
    background: #8ABD1F;
    border-radius: 3px;
    font-weight: 600;
    font-size: 0.8125rem;
    line-height: 123%;
    padding: .375rem .625rem;
    text-transform: uppercase;
    color: #fff;
    opacity: 0.8;

    margin-bottom: 1.18rem;
  }

  &-views {
    display: flex;
    align-items: center;
    margin-bottom: 1.3rem;
    font-weight: 600;
    font-size: 0.8125rem;
    color: #5A79A8;

    &::before {
        content: '';
        width: 1.3rem;
        height: 1.3rem;
        margin-right: 0.6rem;
        background: url(../../assets/icons/view.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

    }
  }

  ::v-deep &-text{
    margin-bottom: 2.5rem;

    p {
      font-size: 0.875rem;
      margin-bottom: 1.25rem;
      line-height: 157%;
      color: rgba(23, 23, 23, 0.8);
    }
  }

  &-tags {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 2.5rem;

    p {
      background: #EEF4FA;
      border-radius: 3px;
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 123%;
      padding: .375rem .625rem;
      text-transform: uppercase;
      color: #003E78;
      opacity: 0.8;
      margin: 0.35rem 0.625rem 0.35rem 0;
    }
  }

  &-social {
      border-top: 1px solid #D5D6D9;
      padding-top: 2.5rem;
  }

  &-img {
    width: 100%;
    height: 27.5rem;
    border-radius: 0.5rem;

    margin-bottom: 2.5rem;
  }

}

</style>

<style lang="scss">

  .stream-video {
    margin-top: 1.62rem;
    position: relative;
    margin-bottom: 2.5rem;

      > iframe {
          height: 32rem;
      }

    &__iframe {
      width: 100%;
      height: 27.5rem;
      display: flex;

      iframe {
        border-radius: 0.5rem;
      }
    }
  }

  @media screen and (max-width: 768px){
    .stream-video  {
      &__iframe {
        width: 100%;
        height: 21.81rem;
      }
    }
  }

   @media screen and (max-width: 420px){
       .stream {
           ::v-deep * {
               font-size: 4vw;
           }
       }

    .stream-video  {
      &__iframe {
        width: 100%;
        height: 12.5rem;
      }
    }
  }

</style>
