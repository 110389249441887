<template>
    <section>
        <router-link class="come-back" :to="`/news?tab=LiveStream&page=${this.$store.getters.getRouteParams && Number(this.$store.getters.getRouteParams.page) || 1}`">
          <div></div>
          {{$store.getters.GET_LANG ? 'Вернуться в раздел “Все прямые трансляции”' : 'Go back to the section “All live broadcasts”'}}
        </router-link>

        <div class="two-columns-wrapper">
          <main>
            <stream-content />
          </main>

          <similar-block-region :title="$store.getters.GET_LANG ? 'Похожие трансляции' : 'Similar broadcasts'"
              :getNews="$store.getters.getPublicLiveStreams.slice(0,3)"
              link="/news?tab=LiveStream"
              card-link="live_stream"
              v-if="$store.getters.getCurrentLiveStream" class="block_region"/>
        </div>

    </section>
</template>
<script>
import StreamContent from '@/components/news/StreamContent'
import SimilarBlockRegion from '@/components/activitiesSurveys/SimilarBlockRegion'
import setMeta from '@/mixins/setMeta'

export default {
  name: 'Stream',

  data: () => {
    return {
      params: {}
    }
  },

  watch: {
    '$store.getters.getCurrentLiveStream' () {
      document.title = this.$store.getters.getCurrentLiveStream.title
      this.setMeta('description', this.$store.getters.getCurrentLiveStream.description)
      this.$store.commit('setFilterId', this.$store.getters.getCurrentLiveStream.id)
      this.$store.dispatch('getPublicLiveStreams')
      this.$store.commit('setFilterId', null)
    }
  },

  beforeDestroy () {
    document.title = 'Общественная палата Российской Федерации'
    this.deleteMeta('description')
    this.$store.commit('setCurrentLiveStream', null)
  },

  mixins: [setMeta],

  components: {
    SimilarBlockRegion,
    StreamContent
  }
}
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 768px) {
    .come-back {
      width: 90%;
    }
    .block_region{
      display: none;
    }
  }
  @media screen and (max-width: 420px) {
    .two-columns-wrapper {
      flex-direction: column;
    }
  }

</style>
